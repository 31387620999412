import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/SEO';
import TheScience from '../components/TheScience/TheScience';

export default function Science({location}){

    return(
        <Layout location={location}>
        
        <Seo title={`The Science`} description = 'The science behind Bender electrolyte powders'  />
        <div className = 'the-science-main'>
            <div className = 'the-science-header'></div>
            <TheScience />
        </div>
        </Layout>
    )

}