import * as React from 'react';
import './Causes.css'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import {StaticImage} from 'gatsby-plugin-image';
//icons 
const bloodVessel  = '../../images/blood-vessel.png'
const stomach =  '../../images/stomach.png'
const liver  = '../../images/liver.png'
const badSleep  = '../../images/bad-sleep.png'
const withdrawal =  '../../images/withdrawal.png'



export default function Causes() {
  return (
    <div>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <StaticImage className = "causes-icon-img" src = {badSleep} alt = 'hangover causes bad sleep icon' width = {50} height = {50} />
            <div className = "causes-title">Sleep Disruption</div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Although alcohol can make you sleepy, it can interfere with the deeper stages of sleep, causing you to wake up in the middle of the night and feel tired the next day.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <StaticImage className = "causes-icon-img" src = {withdrawal} alt = 'hangover alcohol withdrawal icon' width = {50} height = {50} />
            <div className = "causes-title">Alcohol withdrawal</div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            While drinking, your brain adjusts to the effects of alcohol. Mild withdrawal symptoms like nausea, headache, and restlessness can occur when these effects wear off.
          </Typography>
        </AccordionDetails>
      </Accordion>
      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <StaticImage className = "causes-icon-img" src = {liver} alt = 'hangover liver damage icon' width = {50} height = {50} />
            <div className = "causes-title">Acetaldehyde</div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          A chemical called Acetaldehyde is produced while your body breaks down alcohol. In large amounts, Acetaldehyde can lead to symptoms like nausea and sweating.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <StaticImage className = "causes-icon-img" src = {stomach} alt = 'hangover stomach pain icon' width = {50} height = {50} />
            <div className = "causes-title">Digestive upset</div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Consuming alcohol can irritate the lining of your stomach, leading to symptoms like nausea and vomiting.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <StaticImage className = "causes-icon-img" src = {bloodVessel} alt = 'hangover vasodilation icon' width = {50} height = {50} />
            <div className = "causes-title">Widening of blood vessels (vasodilatation)</div>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Alcohol intoxication can cause vasodilatation, which generally leads to headaches.
          </Typography>
        </AccordionDetails>
      </Accordion>
     
    </div>
  );
}

