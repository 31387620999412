
import React from 'react'
import './TheScience.css'
import Causes from '../Causes/Causes'
import Stepper from '../Stepper/Stepper'

const img = '../../images/boxesboozethin.jpg'

const TheScience = () => {
  return (
    <div className="the-science-main">
      {/*<div className = 'the-science-image'>
        <StaticImage src ={img} />
      </div>*/}
      <div className = 'the-science-top-section'>
        <div className="science-text">
          <h2>What Causes Hangovers?</h2>
            <ul >
              <li style ={{listStyleType:'circle'}}>Dehydration/electrolyte imbalance</li>
              <li style ={{listStyleType:'circle'}}>Sleep disruption</li>
              <li style ={{listStyleType:'circle'}}>Alcohol withdrawal</li>
              <li style ={{listStyleType:'circle'}}>Acetaldehyde buildup</li>
              <li style ={{listStyleType:'circle'}}>Digestive upset </li>
              <li style ={{listStyleType:'circle'}}>Vasodilatation</li>
            </ul>
          <p>
          We created Bender to address all of these factors. Every ingredient in our formula was specifically selected to target one of the many factors that can make you question all of your life decisions the morning after drinking.
          </p>
        </div>
        
        <div className='ts-causes'>
          <Causes />
        </div>
      </div>

      <div className = 'ts-section-2'>
        <h2>A 3 Part Approach to Hangover Prevention</h2>
        <p>
        Your body has different needs throughout the night. Our 3 formula approach is designed to get your body exactly what it needs when it needs it most. For example, pre-game prepares your body, Post-game supports your liver and helps you get a restful night of sleep, and Plan-B soothes your stomach and gives you a morning boost.         </p>
        <Stepper />
      </div>
     

      
      

     
    </div>
  )
}
export default TheScience